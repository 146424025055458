.openingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 36px 0 36px;
  border-bottom: 20px solid #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  gap: 120px;
  height: 80vh;
}

// .openingContainer::before {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #f6f6ff;
//   clip-path: polygon(0% 0%, 0% 100%, 130% 0%);
// }

.slideContentBack:global(.MuiPaper-root) {
  background-color: transparent;
  box-shadow: none;
  margin-top: 0px;
}

.slideContent {
  display: flex;
  justify-content: center;
  gap: 32px;
  padding: 12px 0 12px 0;

  img {
    height: auto;
    display: block;
    margin: 0;
  }
}

.apparelImage {
  height: auto;
  min-width: 330px;
  max-width: 330px;
}

.dummyVendors {
  display: flex;
  flex-direction: column;
  // background-color: rgba(195, 197, 205, .45);
  border-radius: 5px;
  height: fit-content;
  margin-top: 25px;
  width: 380px;
  background-color: rgba(203, 203, 220, 0.8);
  border: 1px solid #cbcbdc;
  border-width: thin;

  span {
    font-size: 24px;
    color: #1d1d1f;
  }
}

$selectedSlotColor: rgba(75, 154, 75, 0.75);
.dummyVendorSlot {
  display: flex;
  align-items: center;
  border-top: 1px solid #f5f5f5;
  border-width: thin;
  padding: 12px;

  &[data-selected="true"] {
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 50%,
      #{$selectedSlotColor} 50%,
      #{$selectedSlotColor} 100%
    );
    background-size: 200% 100%;
    animation: slideRight 0.75s linear forwards;
    animation-delay: 1.5s;
  }

  span {
    font-size: 22px;
    color: #1d1d1f;
  }

  .dummyVendorSlot {
    display: flex;
    align-items: center;
    border-top: 1px solid #f5f5f5;
    padding: 12px;
    /* Remove justify-content if it's no longer needed */
  }
  
  .imageContainer {
    flex: 0 0 45%; /* Adjust as needed */
    /* Additional styling */
  }
  
  .inventoryContainer {
    flex: 0 0 38%; /* Adjust as needed */
    /* Additional styling */
  }
  
  .priceContainer {
    flex: 0 0 25%; /* Adjust as needed */
    /* Additional styling */
  }
}

@keyframes slideRight {
  100% {
    background-position: -100% 0;
  }
}

.dummyApparelName {
  padding: 12px;
}

.dummyVendorName {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.vendorBadge {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
  }
}

.welcomeContainer {
  display: flex;
  flex-direction: column;
  min-width: 630px;
  max-width: 630px;
}

.welcomeText {
  color: #1d1d1f;

  span {
    display: block;
    font-size: 33px;
    line-height: 1.66em;
  }

  p {
    font-size: 30px;
    line-height: 1.33em;
  }
}

.welcomeSubText {
  color: black;
  p {
    font-size: 19px;
  }
}

.infoButtonWrapper {
  padding-bottom: 20px;
}

@media (max-width: 1300px) {
  .welcomeContainer {
    display: flex;
    flex-direction: column; // Stack children vertically
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .welcomeText,
  .welcomeSubText,
  .infoButtonWrapper {
    text-align: center; // Center the text inside these containers
    width: 100%; // Use the full width available
  }
}

.infoButton:global(.MuiButton-root),
.infoButton:global(.MuiIconButton-root) {
  background-color: #111;
  color: white;
  margin-right: 2rem;
  margin-top: 1rem;
  width: 200px;
  padding: 10px 0px;
  box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);

  &:hover {
    background-color: white;
    color: rgb(141, 141, 255);
    border: 1px solid rgb(141, 141, 255);
    border-width: thin;
  }

  &[data-get-started="true"] {
    background-color: white;
    color: rgb(25, 118, 210);
    border-radius: 5px;
    text-transform: uppercase;
    border: 1px solid rgb(25, 118, 210);
  }
}

.dummyConnections {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 8px 16px 0px rgba(17, 17, 17, 0.5);
  border-radius: 5px;
  width: 475px;
}

.dummyConnection {
  @extend .dummyVendorSlot;
  display: flex;
  justify-content: space-between;
  padding: 28px;

  span {
    font-size: 25px;
    color: #1d1d1f;
  }

  &[data-top-slot="false"] {
    border-top: 1px solid #c3c5cd;
  }
}

.connectionVendorBadge {
  @extend .vendorBadge;

  width: 30px;
  height: 30px;

  span {
    color: #fff;
  }
}

.dummyConnectionStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 2px solid #5c606f;
  border-radius: 5px;
  padding: 2px 6px 2px 6px;
  width: 175px; // Adjusted width as 1750px seems to be a typo

  svg {
    width: 30px;
    height: 30px;
    color: #5c606f;
  }

  span {
    color: #5c606f;
  }

  &[data-btn-press="true"] {
    animation: mimicPress 0.4s linear alternate;
  }

  &[data-connected="true"] {
    background: #{$selectedSlotColor};

    span {
      color: #fff;
    }

    svg {
      color: #fff;
    }
  }

  @media (max-width: 490px) {
    width: 134px;
  }
}


@keyframes mimicPress {
  100% {
    box-shadow: inset 0px 0px 2px 2px #111;
  }
}

.howItWorksContainer {
  width: 100%;
  padding: 125px 0px 80px 0px;
  // background-color: #f5f5f7;
  border-bottom: 20px solid #fff;
  background-color: #f8f7f0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
}

.howItWorksWrapper {
  display: flex;
  flex-direction: column;
  gap: 58px;

  max-width: 600px;
  width: 100%;
  text-align: left;
  h1 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    color: gray;
  }
}

.stepsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.howItWorksHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: -19px;

  svg {
    width: 62px;
    height: 62px;
    animation: spin 5s linear infinite;
  }

  span {
    font-size: 38px;
    color: #1d1d1f;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.howItWorksStep {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  h3 {
    font-size: 20px;
  }
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(71, 71, 71);
  background: linear-gradient(
    0deg,
    rgba(71, 71, 71, 1) 0%,
    rgba(17, 16, 0, 1) 100%
  );
  border-radius: 50%;
  min-width: 26px;
  max-width: 26px;
  min-height: 26px;
  max-height: 26px;

  span {
    color: #fff;
    font-size: 18px;
  }
}

.stepDesc {
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 24px;
    line-height: 105%;
  }

  p {
    font-size: 18px;
    color: #646464;
  }
}

.faqWrapper {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;
}

.footerWrapper {
  border-top: 1px solid #c3c5cd;
  border-width: thin;
  background-color: #fcfcfc;
  width: 100%;

  span {
    font-size: 17px;
  }
}

.rightsTag {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 1rem;

  > div:last-child {
    margin-top: 1rem;  // This adds 24px of space between the elements
  }

  span {
    color: #5c606f;
    font-size: 16px;
  }
}

.resourceLink {
  cursor: pointer;

  span:hover {
    color: #5c606f;
  }
}

.footerSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sectionTitle {
  span {
    font-size: 21px;
    font-weight: bold;
  }
}

@media (max-width: 1560px) {
  .openingContainer {
    justify-content: space-between;
    gap: 0px;
  }

  .slideContent {
    z-index: -1;
    margin-top: 0;
  }

  .welcomeContainer {
    min-width: 0;
  }
}

@media (max-width: 1450px) {
  .apparelImage {
      min-width: 250px;
      max-width: 250px;
    }

  .dummyVendors {
    width: 320px;

    span {
      font-size: 22px;
    }
  }

  .dummyVendorSlot {
    gap: 1px;

    span {
      font-size: 18px;
    }
  }
}

@media (max-width: 1320px) {
  .openingContainer {
    justify-content: center;
  }

  .welcomeContainer {
    min-width: 675px;
    max-width: 675px;
    background-color: #f6f6ff;
    z-index: 2;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1300px) {
  .openingContainer {
    height: fit-content;
  }
}

@media (max-width: 1250px) {
  .howItWorksContainer {
    flex-direction: column;
    gap: 55px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .howItWorksHeader {
    margin-left: 0px;
    justify-content: center;
    padding-bottom: 1rem;
  }
}

@media (max-width: 765px) {
  .slideContentBack:global(.MuiPaper-root) {
    margin-top: 0px;
  }

  .slideContent {
    margin-top: 25px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
  }
}

@media (max-width: 640px) {
  .stepsWrapper {
    padding: 0 12px 0 12px;
  }
}

@media (max-width: 490px) {
  .dummyConnections {
    width: 370px;
  }
}

@media (max-width: 700px) {
  .welcomeContainer {
    padding-left: 6px;
    padding-left: 6px;
    min-width: 380px;
  }
}

@media (max-width: 400px) {
  .dummyVendors {
    width: 372px;
  }
}

.logoContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  height: auto;
  width: 140px;
}

.centeredContent {
  display: flex;
  gap: 72px;
  justify-content: center;

  // Media query for screen width 500px or less
  @media (max-width: 440px) {
    flex-direction: column;
    gap: 36px;
    margin-left: 24px;
    margin-top: 12px;
  }
}

.emptySpace {
  width: 120px;
}

@media (max-width: 650px) {
  .rightsTag {
    align-self: flex-start;
    padding-left: 10px;
  }

  .logoContainer {
    padding-bottom: 15px;
  }

  .centeredContent {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
