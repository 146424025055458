.cartPage {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin: auto;
  padding-top: 55px;
  padding-bottom: 55px;
  padding-left: 45px;
  padding-right: 45px;
  background-color: #fcfcfc;

  @media (max-width: 1300px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.cartFilterBar {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #c7c7c7;
  height: fit-content;
  width: 200px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  > {
    :last-child {
      border-bottom: none;
      border-bottom-right-radius: 4px;

      div {
        border-bottom-right-radius: 4px;
      }

      :global(.MuiBox-root) {
        border-bottom-right-radius: 26px;
      }
    }

    :first-child {
      border-top-right-radius: 4px;

      div {
        border-top-right-radius: 4px;
      }

      :global(.MuiBox-root) {
        border-top-right-radius: 26px;
      }
    }
  }

  @media (max-width: 1130px) {
    display: none;
  }
}

.cartFilterWrapper {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #d4d4d4;
  cursor: pointer;
  background-color: #dddddd;

  &[data-selected="true"] {
    background-color: rgb(2 129 192); // lighterblueblue
  }

  @media (max-width: 1030px) {
    display: none;
  }
}

.cartFilter {
  width: 98%;
  min-height: 100%;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #f4f4f4;
  }

  &[data-selected="true"] {
    background-color: #EEF3F5; // dgiWhite
  }


  @media (max-width: 1030px) {
    display: none;
  }
}

.cartCheckout {
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 25px;

  &[data-loading-carts="true"] {
    height: fit-content;
  }
}

.checkoutHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  border: 1px solid #e0e3e9;
  border-width: thin;
  max-height: 120px;
}

.headerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
}

.refreshCartWrapper:global(.MuiIconButton-root) {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  @media (max-width: 600px) {
    display: none;
  }
}

.checkoutTotals {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}

.tableWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px;
  padding-top: 0;
}

.loadingCartsSpinner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loadingCartsMsg {
  font-size: 22px;
  color: #313131;
}

.vendorCarts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.vendorCart {
  border: 1px solid #e0e3e9;
  height: fit-content;
}

.vendorCartNameWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  border-bottom: 1px solid #e0e3e9;
}

.freeShippingStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
  width: 620px;
  padding-right: 20px;
  gap: 10px;
}

@media (max-width: 1200px) {
  .freeShippingStatus {
    width: 440px;
  }
}

@media (max-width: 750px) {
  .freeShippingStatus {
    display: none;
  }
}

.customTooltip {
  background-color: white !important;
  color: #343231 !important;
  font-size: 16px !important;
  border: 1px lightgray solid !important;
}

.settingsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: darkgray;
  cursor: pointer;
  font-size: 16px;
  gap: 4px;
  padding-right: 10px;
}

.settingsButtonText {
  color: darkgray;
  font-size: 15px;
  line-height: 1;
}

@media (max-width: 1200px) {
  .settingsButtonText {
    display: none;
  }
}

.dialogContainer {
  width: 400px;
  max-width: 100%;

  &.MuiDialog-paper {
    width: 100%;
  }
}

.shippingDialogTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 8px !important;
}
.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 16px !important;
}

.dialogActions:global(.MuiDialogActions-root) {
  justify-content: space-between;
  padding: 16px 60px 16px 60px;
}

.shippingInputField {
  padding-top: 30px !important;
}

.vendorCartHeader:global(.MuiAccordionSummary-content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 36px;
}

.vendorCartHeader:global(.MuiAccordionSummary-root) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 48px;
  min-height: 0px;
}

.MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e3eaf1;
}

.freeShippingStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
  width: 400px;
  padding-right: 20px;
  gap: 10px;
}

@media (max-width: 1200px) {
  .freeShippingStatus {
    width: 400px;
  }
}

@media (max-width: 750px) {
  .freeShippingStatus {
    display: none;
  }
}

.customTooltip {
  background-color: white !important;
  color: #343231 !important;
  font-size: 16px !important;
  border: 1px lightgray solid !important;
}

.settingsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: darkgray;
  cursor: pointer;
  font-size: 16px;
  gap: 4px;
  padding-right: 10px;
}

.settingsButtonText {
  color: darkgray;
  font-size: 15px;
  line-height: 1;
}

@media (max-width: 1200px) {
  .settingsButtonText {
    display: none;
  }
}

.dialogContainer {
  width: 400px;
  max-width: 100%;

  &.MuiDialog-paper {
    width: 100%;
  }
}

.shippingDialogTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 16px;
}
.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dialogActions {
  justify-content: space-between;
  padding: 16px 60px 16px 60px;
}

.shippingInputField {
  padding-top: 16px;
}

.vendorCartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendorCartData {
  display: flex;
  background-color: white;
}

.checkoutItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 15px 8px 15px;
  width: 100%;

  > :last-child {
    border-bottom: none;
  }

  @media (max-width: 600px) {
    padding: 15px 15px 8px 15px;
  }
}

.checkoutItemContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;

  > .detailsAndPricingWrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-right: 20px;
    gap: 30px;

    > .checkoutItemDetails {
      flex-grow: 1;
      flex-basis: 65%;
      min-width: 200px;
    }

    > .checkoutItemEntry {
      flex-grow: 1;
      flex-basis: 35%;
    }
  }

  @media (max-width: 1200px) {
    > .detailsAndPricingWrapper {
      flex-direction: column;
      align-items: flex-start;
      > .checkoutItemDetails {
        flex: 1;
        padding-right: 0;
        width: calc(100% - 10px);
        min-width: 200px;
      }
      > .checkoutItemEntry {
        flex: 1;
        padding-top: 16px;
        width: calc(100% - 10px);
      }
    }
  }

  @media (max-width: 600px) {
    > .detailsAndPricingWrapper {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 0px;
      > .checkoutItemDetails {
        flex: 1;
        padding-right: 0;
        width: 100%;
        min-width: 200px;
      }
      > .checkoutItemEntry {
        flex: 1;
        padding-top: 16px;
        width: 100%;
      }
    }
  }
}

.checkoutItemStyle {
  font-size: 18px;
  font-weight: bold;
}

.checkoutItemEntry {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  > .checkoutItemPriceData {
    flex: 1;  // Changed from flex-basis: 70% to flex: 1
    min-width: 200px;
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div:nth-child(2) {  // SwitchCartInterface wrapper
    flex: 0 0 auto;  // Don't grow or shrink, size to content
    min-width: auto;
    max-width: fit-content;
  }

  > button {
    flex: 0 0 auto;  // Don't grow or shrink
    min-width: 40px;
    max-width: 40px;
  }

  @media (max-width: 1200px) {
    > .checkoutItemPriceData {
      flex: 1;
      max-width: none;
    }
  }
}

.checkoutItemPriceData {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    min-width: none;
  }

  .sizeCell {
    flex: 0.03;
    padding: none;
    padding-top: 2px;
    display: flex;
    justify-content: flex-start;
    min-width: 66px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .quantityInputCell {
    flex: 0.2;
    display: flex;
    justify-content: center;
    min-width: 50px;
    padding: 0px 2px 0 2px;
    margin-left: -10px;
  }

  .multiplicationCell {
    flex: 0.04;
    display: flex;
    justify-content: center;
    min-width: 12px;
  }

  .unitPriceCell {
    flex: 0.2;
    display: flex;
    justify-content: center;
    min-width: 50px;
  }

  .equalCell {
    flex: 0.03;
    display: flex;
    justify-content: center;
  }

  .warehouseCell {
    flex: 0.12;
    display: flex;
    justify-content: center;
    min-width: 60px;
  }

  > div:last-child {
    flex: 0.25;
    display: flex;
    justify-content: flex-start;
    min-width: 140px;
    padding-left: 8px;
  }
}

.itemQuantityField {
  width: 50px;

  input {
    text-align: center;
    padding: 3px 0 1px 0;
    line-height: 1;
  }
}

.saveButton:global(.MuiButton-root) {
  margin-left: auto;
  background-color: #2289ec;
  height: 24px;
  width: 40px;
  color: white;
  padding: 0px;
  text-transform: none;
  &:hover {
    background-color: darkblue;
  }
}

.entryTotalPriceWrapper {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 600px) {
    display: none;
  }
}

.cartSummary {
  margin-left: auto;
  padding: 25px 10px 25px 10px;
  border-left: dashed gray 1px;
  width: 200px;
  flex-shrink: 0;
  flex-grow: 0;

  @media (max-width: 800px) {
    display: none;
  }
}

.cartSummaryContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.cartSubtotal {
  text-align: center;
  .cartSubtotalValue {
    color: black;
    font-size: 18px;
    font-weight: 500;
  }
}

.viewCartButton:global(.MuiButton-root) {
  width: 184px;
  text-transform: none;
  margin-top: 16px;
  font-size: 0.9em;
  &:disabled {
    background-color: grey;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideCart {
  flex-direction: column;
  gap: 19px;
  width: 240px;
  padding: 0em 0em 0em 0em;
  height: fit-content;
  align-items: center;

  display: none;

  @media (min-width: 1350px) {
    display: flex;
  }
}

.sideCartHeader {
  border: 1px solid #d2d6da;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  width: 240px;
  justify-content: space-between;
  padding: 8px 12px 8px 12px;
}

.sideVendorCarts {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 240px;
}

.sideCartSkeleton {
  display: flex;
  height: 48px;
  width: 100%;
}

.sideVendorCart {
  background-color: #fff;
  border: 1px solid #d2d6da;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.sideCheckoutItems {
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  :last-child {
    border-bottom: none;
  }
}

.sideCheckoutItem {
  padding-bottom: 16px;
  border-bottom: 1px solid #e3eaf1;
}

.sideItemInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sideCartInfoTag {
  color: #5c606f;
}

.showCartItemsPrompt {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
}

.totalQuantity {
  display: flex;
  align-items: center;
  gap: 3px;
}

.cartItemsPromptIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slideSizedItemsWrapper {
  position: relative;
  overflow: hidden;
  max-height: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

  &[data-open="true"] {
    max-height: 100%;
    opacity: 1;
  }
}

.slideCheckoutItemsWrapper {
  @extend .slideSizedItemsWrapper;
  cursor: default;
}

.slideSizedItems {
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 1000px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 4px;
}

.slideCheckoutItems {
  @extend .slideCheckoutItems;
  cursor: default;
}

.sideSizedItems {
  padding: 4px;
}

.sideSizedItem {
  display: grid;
  padding: 0 20px 0 20px;
  grid-template-columns: 20% 18% 40%;
  gap: 20px;
  align-items: center;

  span {
    font-size: 14px;
  }
}

.vendorLogoImage {
  max-height: 36px;
  padding-top: 4px;
  padding-left: 8px;
  opacity: 1;

  &[data-downsize="true"] {
    max-height: 28px;
  }
}

.dataSpinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  gap: 0.5rem;
  width: 260px;
  height: 100px;
  color: #2e9fff;

  span {
    font-size: 1em;
  }
}

.dataSpinner:global(.MuiCircularProgress-root) {
  color: #2e9fff;
  height: 30px;
  margin-bottom: 0.5rem;
}

.switchWarehouseButton:global(.MuiButtonBase-root) {
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  border: 1px solid #c9c9c9;
  padding: 1px 3px 1px 3px;
  justify-content: flex-start;
  border-radius: 4px !important;
  border-radius: 6px !important;
  text-transform: none;
  font-weight: 400;

  &:disabled {
    outline: none !important;
    border: none !important;
  }

  span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.noWarehouseOptions {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #888;
}

.switchWhseButtons {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  padding-top: 4px;
}

.checkoutItemImage {
  max-height: 120px;
  max-width: 120px;
}
